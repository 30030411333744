import React, { useEffect, useState } from "react";
// import { Modal, Button } from "react-bootstrap";
import job_banner from "../../../assets/img/home/slider/newbanner/job_banner.png";
import img23 from "../../../assets/img/home/slider/newbanner/firstbanner.webp";
import img24 from "../../../assets/img/home/slider/newbanner/banner7-mobile.jpg";
import img25 from "../../../assets/img/home/slider/newbanner/banner5.webp";
import img26 from "../../../assets/img/home/slider/newbanner/banner311.webp";
import img27 from "../../../assets/img/home/slider/newbanner/banner21.webp";
import img28 from "../../../assets/img/home/slider/newbanner/nabh-website.png";
import img29 from "../../../assets/img/home/slider/newbanner/banner11.webp";
import img30 from "../../../assets/img/home/slider/newbanner/banner44.webp";
import img31 from "../../../assets/img/home/slider/newbanner/banner8.webp";
import img32 from "../../../assets/img/home/slider/newbanner/wev_doc_grp2.jpeg";
import img33 from "../../../assets/img/home/slider/newbanner/banner-epicorium.jpg";
import img34 from "../../../assets/img/home/slider/newbanner/doc-banner.png";
import downloadPdf from "../../../assets/application-fellowship.docx";
import { Link } from "react-router-dom";

// import errorpage from "../../../data/ErrorPage";
const Slider = () => {
  // useEffect(() => {
  //   let imageValue = document.getElementById("updateMyImage");
  //   window.onload = function () {
  //     imageValue.src = img23;
  //   };
  //   window.onresize = function () {
  //     if (window.innerWidth < 768) {
  //       imageValue.src = img24;
  //       // console.log("Hi Raghu");
  //     } else imageValue.src = img23;
  //   };
  //   // setTimeout(function () {
  //   //   handleShow();
  //   // }, 11000);
  // }, []);
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <>
      <div id="bannerControls" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner" style={{ padding: "0px 0px 7px 0px" }}>
          {" "}
          {/* <div className="carousel-item active">
            <a href="registrar">
              <img src={img34} className="d-block w-100" alt="{img5}" />
            </a>
          </div> */}
          <div className="carousel-item active">
            <a href="https://cutis.org.in/careers">
              <img
                src={job_banner}
                className="d-block w-100"
                alt="{img5}"
                loading="lazy"
              />
            </a>
          </div>
          <div className="carousel-item  ">
            <img
              src={img23}
              className="d-block w-100 "
              alt="{img5}"
              id="updateMyImage"
              loading="lazy"
            />
          </div>
          {/* <div className="carousel-item" id="mostOfVisisble">
            <img src={img24} className="d-block w-100" alt="{img5}" />
          </div> */}
          <div className="carousel-item">
            <img
              src={img25}
              className="d-block w-100"
              alt="{img5}"
              loading="lazy"
            />
          </div>
          <div className="carousel-item">
            <img
              src={img26}
              className="d-block w-100"
              alt="{img5}"
              loading="lazy"
            />
          </div>
          <div className="carousel-item">
            <img
              src={img27}
              className="d-block w-100"
              alt="{img5}"
              loading="lazy"
            />
          </div>
          <div className="carousel-item">
            <img
              src={img32}
              loading="lazy"
              className="d-block w-100"
              alt="{img5}"
            />
          </div>
          <div className="carousel-item">
            <img
              src={img28}
              className="d-block w-100"
              alt="{img5}"
              loading="lazy"
            />
          </div>
          <div className="carousel-item">
            <img
              src={img29}
              className="d-block w-100"
              alt="{img5}"
              loading="lazy"
            />
          </div>
          <div className="carousel-item">
            <img
              src={img30}
              className="d-block w-100"
              alt="{img5}"
              loading="lazy"
            />
          </div>
          <div className="carousel-item">
            <h2 id="dedicated-banner-content">
              Dedicated Geriatric & Pediatric Department
            </h2>
            <img
              src={img31}
              className="d-block w-100"
              alt="{img5}"
              loading="lazy"
            />
          </div>
          <div className="carousel-item">
            <div className="carousel-btn-container">
              <a href="https://www.cristello.in/" target="_blank">
                Shop Now
              </a>
            </div>
            <img
              src={img33}
              className="d-block w-100"
              alt="{img5}"
              loading="lazy"
            />
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#bannerControls"
          role="button"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon-span">
            <i className="carousel-control-prev-icon">&lt; </i>
          </span>
          {/* <span className="sr-only">Previous</span> */}
        </a>
        <a
          className="carousel-control-next"
          href="#bannerControls"
          role="button"
          data-slide="next"
        >
          <span className="carousel-control-next-icon-span">
            <i className="fas fa-lesser-than carousel-control-next-icon"></i>
          </span>
        </a>
      </div>

      {/* <div className="top-bar-mask-content">
        <a href="/fellowship" className="fellowship-text">
          List of selected candidates for fellowship programme, August 2023
          batch is out
        </a>
      </div> */}
      {/* <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#3fbbc0" }}>
            ADMISSION FOR FELLOWSHIP PROGRAM SEP 2021
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-2">
          <p style={{ fontSize: "18px" }}>
            Applications are invited from eligible candidates for one vacant
            seat to be filled in PEDIATRIC DERMATOLOGY fellowship program.
          </p>
          <p>Last date for submission of application: 16.09.2021 by 4:00PM</p>
          <p>
            Kindly contact for further details
            <br />
            Mr. Sudheer Chandra
            <br /> Admin-Academic Section
            <br /> Mobile.No :8129717802
          </p>
        </Modal.Body>
      </Modal> */}
      {/* <marquee width="100%" direction="left" height="100px">
        Applications are invited for the Fellowship course for September
        2022-2023 batch. &nbsp;
        <a href={downloadPdf} download>
          (Download the Application Form) &nbsp;
        </a>
        - For more info
        <a href="https://cutis.org.in/fellowship" target="_blank">
          &nbsp; click here.
        </a>
      </marquee> */}
    </>
  );
};

export default Slider;
