import React from "react";
import SectionTitle from "./home/SectionTitle";

const iframeStyle = { border: 0, width: "100%", height: "350px" };

const Registers = () => {
  return (
    <>
      <div className="container">
        <div className="container_nurse">
          <SectionTitle
            title={"Job Description  Dermatology Registrar"}
            desc={""}
          />
          <p>
            We are looking for an young dermatologist to join
            <strong> CUTIS Academy of Cutaneous sciences.</strong>
          </p>
          <p>
            <strong> Responsibilities </strong>
            <ul>
              <li>Evaluate patients’ skin condition</li>
              <li>Keep a record of patients’ symptoms</li>
              <li>Analyse all information regarding skin health conditions</li>
              <li>Prescribe medication</li>
              <li>Inform patients about available treatments</li>
              <li>Monitor the effectiveness of skin treatments</li>
              <li>Conduct non-intrusive/invasive medical surgeries</li>
              <li>Educate patients on preventive skin care</li>
              <li>
                Attend conferences and seminars to learn about new medical
                techniques and topics
              </li>
            </ul>
          </p>
          <p>
            <strong>Requirements</strong>
          </p>
          <ul>
            <li>In-depth knowledge of various dermatological methods</li>
            <li>Ability to analyze complex information</li>
            <li>
              Active communication skills and the ability to effectively explain
              medical terms to patients
            </li>
            <li>A resilient and compassionate personality</li>
            <li>Strong attention to detail</li>
            <li>
              KMC registration updated with post graduate degree, MD degree
              certificate
            </li>
          </ul>
          <p>
            <strong>Job Types: </strong>
            Full-time, Fresher
          </p>
          <p>
            <strong>Experience-Required: </strong>
            Fresher to 1 year experience post MD
          </p>
          <p>
            <strong>Salary: </strong>
            Starts From ₹ 72,000.00 per month onwards and will increase based on
            the years of experience.
          </p>
          <p>
            Send resume to <a href="mailto:hr@cutis.org.in">hr@cutis.org.in</a>
          </p>
          <strong>NOTE: Private practice not permitted</strong>
        </div>
      </div>
    </>
  );
};

export default Registers;
