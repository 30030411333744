import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import customdata from "./helper/SEO/TitleDescription.json";
const Helemt = (props) => {
  const [metaTag, setMetaTag] = useState({});
  useEffect(() => {
    customdata.map((value, key) => {
      window.location.pathname === value.myUrl && setMetaTag(value);
      return key;
    });
  }, [window.location.pathname]);

  function removeTrailingSlash(url) {
    return url.endsWith("/") ? url.slice(0, -1) : url;
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={metaTag.DESCRIPTION} />
        <title>{metaTag.TITLE}</title>
        <link
          rel="canonical"
          href={removeTrailingSlash(window.location.href)}
        />

        {/* Og Tags  */}

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={metaTag.TITLE} />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://cutis.org.in/static/media/nabh-website.b0754a5f.png"
        />
        <meta property="og:description" content={metaTag.DESCRIPTION} />
        <meta
          property="business:contact_data:street_address"
          content="5/1, 4th Main, MRCR Layout, Magadi Main Rd, Govindaraja Nagar Ward, MC Layout"
        />
        <meta property="business:contact_data:locality" content="Bangalore" />
        <meta property="business:contact_data:region" content="Karnataka" />
        <meta property="business:contact_data:postal_code" content="560040" />
        <meta property="business:contact_data:country_name" content="India" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="cutis.org.in" />
        <meta property="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content={metaTag.TITLE} />
        <meta name="twitter:description" content={metaTag.DESCRIPTION} />
        <meta
          name="twitter:image"
          content="https://cutis.org.in/static/media/firstbanner.032cf9de.webp"
        />
      </Helmet>
    </>
  );
};

export default Helemt;
