import React from "react";
import SectionTitle from "./home/SectionTitle";

const iframeStyle = { border: 0, width: "100%", height: "350px" };

const ConsultantsC = () => {
  return (
    <>
      <div className="container">
        <div className="container_nurse">
          <SectionTitle
            title={"Job Description Dermatology Consultant"}
            desc={""}
          />
          <p>
            We are looking for an experienced dermatologist to join
            <strong> CUTIS Academy of Cutaneous sciences.</strong>
          </p>
          <p>
            <strong>Job Brief:</strong>
            <ul>
              <li>Conduct OPD’s in individual capacities</li>
              <li>Evaluate patients’ skin condition</li>
              <li>Assess and update patients’ medical history</li>
              <li>Keep a record of patients’ symptoms</li>
              <li>Analyze all information regarding skin health conditions</li>
              <li>
                Prescribe medication & Inform patients about available
                treatments
              </li>
              <li>Monitor the effectiveness of skin treatments</li>
              <li>Conduct non-intrusive/invasive medical surgeries</li>
              <li>
                Attend conferences and seminars to learn about new medical
                techniques and topics
              </li>
              <li>Performs procedures independently</li>
            </ul>
          </p>
          <p>
            <strong>Qualification</strong>
            <ul>
              <li>Candidates should have completed MD/DNB in Dermatology</li>
              <li>
                Preference would be given for candidates who has completed
                Fellowship in Aesthetic dermatology, Dermatosurgery
                respectively.
              </li>
            </ul>
          </p>
          <p>
            <strong>Requirements</strong>
          </p>
          <ul>
            <li>Previous experience as a dermatologist</li>
            <li>In-depth knowledge of various dermatological methods</li>
            <li>Ability to analyse complex information</li>
            <li>
              Active communication skills and the ability to effectively explain
              medical terms to patients
            </li>
            <li>A resilient and compassionate personality</li>
            <li>Strong attention to detail</li>
            <li>
              KMC registration updated with post graduate degree, MD degree
              certificate & work experience certificate if any.
            </li>
          </ul>
          <p>
            <strong>Job Types: </strong>
            Full-time
          </p>
          <p>
            <strong>Experience-Required: </strong>
            Preferred 2 years’ experience post MD
          </p>
          <p>
            <strong>Salary: </strong>
            starts from 1,00,000 per month onwards and will increase based on
            the years of experience
          </p>
          <p>
            Send resume to <a href="mailto:hr@cutis.org.in">hr@cutis.org.in</a>
          </p>
          <strong>NOTE: Private practice not permitted</strong>
        </div>
      </div>
    </>
  );
};

export default ConsultantsC;
